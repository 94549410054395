exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-carreers-engeener-js": () => import("./../../../src/pages/carreers/engeener.js" /* webpackChunkName: "component---src-pages-carreers-engeener-js" */),
  "component---src-pages-carrers-js": () => import("./../../../src/pages/carrers.js" /* webpackChunkName: "component---src-pages-carrers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-services-cloud-transformation-js": () => import("./../../../src/pages/services/cloudTransformation.js" /* webpackChunkName: "component---src-pages-services-cloud-transformation-js" */),
  "component---src-pages-services-data-management-js": () => import("./../../../src/pages/services/dataManagement.js" /* webpackChunkName: "component---src-pages-services-data-management-js" */),
  "component---src-pages-services-elite-3-e-js": () => import("./../../../src/pages/services/elite3e.js" /* webpackChunkName: "component---src-pages-services-elite-3-e-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-offshore-development-js": () => import("./../../../src/pages/services/offshoreDevelopment.js" /* webpackChunkName: "component---src-pages-services-offshore-development-js" */),
  "component---src-pages-services-software-application-management-js": () => import("./../../../src/pages/services/softwareApplicationManagement.js" /* webpackChunkName: "component---src-pages-services-software-application-management-js" */),
  "component---src-pages-services-software-quality-assurance-js": () => import("./../../../src/pages/services/softwareQualityAssurance.js" /* webpackChunkName: "component---src-pages-services-software-quality-assurance-js" */),
  "component---src-pages-services-technical-consulting-js": () => import("./../../../src/pages/services/technicalConsulting.js" /* webpackChunkName: "component---src-pages-services-technical-consulting-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

